import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AboutComponent } from './about/about.component';
import { AppComponent } from './app.component';
import { BackToHomeComponent } from './back-to-home/back-to-home.component';
import { BlogComponent } from './blog/blog.component';
import { BooksComponent } from './books/books.component';
import { LandingComponent } from './landing/landing.component';
import { NavComponent } from './nav/nav.component';
import { NotesComponent } from './notes/notes.component';
import { HomeComponent } from './home/home.component';
import { ProjectsComponent } from './projects/projects.component';

import * as $ from "jquery";
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AboutComponent,
    AppComponent,
    BackToHomeComponent,
    BooksComponent,
    BlogComponent,
    HomeComponent,
    LandingComponent,
    NavComponent,
    NotesComponent,
    ProjectsComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AppRoutingModule,
    BrowserModule,
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';

interface Book {
  title: string;
  author: string;
  review: string[];
  rating: number;
  cover: {
    identifier_type: string;
    identifier_value: string;
  };
};

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss']
})
export class BooksComponent implements OnInit {
  nowReading: Observable<any>;
  nowReadingObj: any;

  allBooks: Observable<any>;
  allBooksList: any[] = [];

  isShowingFullReview: boolean = false;
  bookForReview: Book;

  loading: boolean = true;

  constructor(database: AngularFireDatabase) {
    this.nowReading = database.object('now_reading').valueChanges();
    this.allBooks = database.object('all_books').valueChanges();

    this.nowReading.subscribe(val => {
      this.nowReadingObj = val;
    });

    this.allBooks.subscribe(val => {
      Object.keys(val).reverse().map(year => {
          this.allBooksList.push({
            'year': year,
            'books': val[year].sort((a, b) => {
              const a_date = new Date(a.date_finished);
              const b_date = new Date(b.date_finished);

              if (a_date > b_date) return -1;
              else if (a_date < b_date) return 1;
              return 0;
            }),
          });
        },
        {}
      );
      this.loading = false;
    });
  }

  public getCurrentYear = () => new Date().getFullYear().toString();

  public getImageUrl = () => `url(http://covers.openlibrary.org/b/${this.nowReadingObj.cover.identifier_type}/${this.nowReadingObj.cover.identifier_value}-L.jpg)`;

  public keepOriginalOrder = (a: { key: string }, b: string) => a.key;

  public showFullReview(book?: Book) {
    this.isShowingFullReview = !this.isShowingFullReview;
    if (this.isShowingFullReview) {
      this.bookForReview = book;
    }
  }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  navItems = {
    '#landing': 'About',
    '#projects': 'Projects',
    '/books': 'Books',
  };
  activeSection = '';

  constructor(private router: Router) {
    router.events.pipe(
      filter(e => e instanceof NavigationEnd),
    ).subscribe(() => {
      this.activeSection = Object.keys(this.navItems).find(r => this.router.url.includes(r), this) || '';
    });
  }

  public keepOriginalOrder = (a: { key: string }, b: string) => a.key;

  ngOnInit() {
  }

}

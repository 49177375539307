import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $(".show-more").on("click", (e) => {
      $(e.target).parent().siblings(".content").collapse("toggle");
    });
    
  }

}
